import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../utils/Firebase/firebase";
import "./EbookComponent.css";
import BookComponent from "../../../Pages/BookComponent/BookComponent";

const EbookComponent = () => {
  const desiredCategory = "book";
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Set loading to true when starting to fetch
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const fetchedProducts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products: ", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchProducts();
  }, []);

  const filteredData = products
    .filter((product) => product.category === desiredCategory)
    .slice(0, 4);

  return (
    <div className="bookstore">
      <div className="book-list">
        {loading ? ( // Show loading indicator while fetching
          <p>Loading products...</p>
        ) : filteredData.length > 0 ? (
          filteredData.map((book) => (
            <BookComponent key={book.id} book={book} />
          ))
        ) : (
          <p>No Books available in this category.</p>
        )}
      </div>
    </div>
  );
};

export default EbookComponent;
