import React, { useState } from "react";
import axios from "axios";
import { XMLParser } from "fast-xml-parser"; // Import fast-xml-parser

const PaymentForm = () => {
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const PaynowURL = "https://secure.3gdirectpay.com/payv2.php?ID="

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Parse amount to float
    const parsedAmount = parseFloat(amount);

    // Validate that amount is a valid number and format it to 4 decimal places
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
        alert("Please enter a valid positive number for the amount.");
        return;
    }

    // Format the amount to 4 decimal places
    const formattedAmount = parsedAmount.toFixed(4);

    // Validate the date format
    const formattedDate = formatDate(date);
    if (!formattedDate) {
        alert("Please enter a valid date in the format YYYY-MM-DD.");
        return;
    }

    const paymentData = {
        paymentAmount: formattedAmount, // Use the formatted amount
        serviceDescription: description,
        serviceDate: formattedDate, // Use the formatted date
    };

    try {
        const response = await axios.post(
            "http://localhost:4000/api/payment",
            paymentData
        );

        // Parse the XML response using fast-xml-parser
        const parser = new XMLParser();
        const result = parser.parse(response.data);
        const transToken = result.API3G.TransToken;
        const transRef = result.API3G.TransRef;

        console.log("Payment response:", response.data);
        console.log("TransToken:", transToken);
        console.log("TransRef:", transRef);

        // Navigate to the PaynowURL with TransToken
        window.location.href = `${PaynowURL}${transToken}`;

    } catch (error) {
        console.error("Error sending payment data:", error);
    }
};


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Check if the date is valid
    if (isNaN(date.getTime())) return null;
    // Return in YYYY-MM-DD format
    return date.toISOString().split("T")[0]; // Gets YYYY-MM-DD
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Service Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        required
      />
      <input
        type="date" // Changed to date input type
        placeholder="Service Date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        required
      />
      <button type="submit">Submit</button>
    </form>
  );
};

export default PaymentForm;
