import React from 'react'
import "./CSS/CreateDash.css"
import SingleHeader from '../../../components/Header/SingleHeader'

export default function CreateDash() {
  return (
    <>
    <SingleHeader title="Select to create" />
    <div className="create-section">
        <div className="container">
        <a href="/create-video" className="button">Create Video</a>
        <a href="/createblog" className="button">Create Blog</a>
        <a href="/Createproduct" className="button">Create Product</a>
        <a href="/Createbook" className="button">Create Book</a>
    </div>
    </div>
    </>
  )
}
