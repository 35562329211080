import React from "react";
import "./TestimonialSection.css";
import testimonial from "../../../utils/img/Hero/testimonialpic.webp";
import Jennifer_Namyalo from "../../../utils/img/Testimonial/Jennifer_Namyalo.jpg";
import Biyinzika_Pauline from "../../../utils/img/Testimonial/Biyinzika_Pauline.jpeg";

const testimonials = [
  {
    id: 1,
    name: "Monic Zawi",
    feedback:
      "Fantastic guidance and support. My hair has never looked better!",
    image: testimonial,
  },
  {
    id: 2,
    name: "Jennifer Namyalo",
    feedback:
      "Primah is very intentional with every one she pours her knowledge into. My hair journey started because of her, and I can look back only with gratitude that I met her.",
    image: Jennifer_Namyalo,
  },
  {
    id: 3,
    name: "Biyinzika Pauline",
    feedback:
      "I've enjoyed Prima's natural hair content, especially her detailed videos on embracing natural hair in any state. As someone with natural hair, it can be tough to find a comfortable style without stress. Prima's advice on rocking the same hairstyle for a week has reduced my hair breakage and scalp strain. My hair is now healthier, and I've grown to love it more each day.",
    image: Biyinzika_Pauline,
  },
];

const TestimonialSection = () => {
  return (
    <section className="testimonial-section">
      <h2>What Our Clients Say</h2>
      <div className="testimonial-container">
        {testimonials.map(({ id, name, feedback, image }) => (
          <div key={id} className="testimonial-card">
            <img src={image} alt={name} className="testimonial-image" />
            <h3 className="testimonial-name">{name}</h3>
            <p className="testimonial-feedback">{feedback}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TestimonialSection;
