import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import YouTube from 'react-youtube';
import { FaLock, FaCheck } from 'react-icons/fa';
import "./VideoPlayer.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import VideoComponent from '../Home/Videos/VideoComponent/VideoComponent';
import video1 from '../../utils/img/Hero/video-thumb.png';
import video2 from '../../utils/img/Hero/videoBanner.png';
import video3 from '../../utils/img/Hero/VideoThumbnail.png';

const videos = [
  { id: 'N1eYCuAhsEI', title: 'Video 1' },
  { id: 'lXWAJyTV8Wk', title: 'Video 2' },
  { id: 'GASB9WRQx6o', title: 'Video 3' },
];

const suggestedVideos = [
  {
    src: video1,
    date: '17/Aug',
    category: 'Hair care',
    title: 'Taking Care of Yourself: Its Not Just About You',
    rating: '5.0/7 Rating',
    topics: '3 Topics',
    views: '20 Views',
    alt: 'Taking Care of Yourself: Its Not Just About You',
  },
  {
    src: video2,
    date: '18/Aug',
    category: 'Hair Loss',
    title: 'Explore the causes of hair loss and understand whether its stress-related or genetic.',
    rating: '4.5/9 Rating',
    topics: '5 Topics',
    views: '35 Views',
    alt: 'Explore the causes of hair loss',
  },
  {
    src: video3,
    date: '20/Aug',
    category: 'Hair Growth',
    title: 'Discover effective methods to promote faster and stronger hair growth.',
    rating: '4.9/7 Rating',
    topics: '4 Topics',
    views: '18 Views',
    alt: 'Faster and stronger hair growth.',
  },
];

export default function VideoPlayer() {
  const { videoId } = useParams();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [completedVideos, setCompletedVideos] = useState([]);

  useEffect(() => {
    if (videoId) {
      const index = videos.findIndex(video => video.id === videoId);
      if (index !== -1) {
        setCurrentVideoIndex(index);
      }
    }
  }, [videoId]);

  const handleEnd = () => {
    setCompletedVideos(prev => [...prev, currentVideoIndex]);
    if (currentVideoIndex < videos.length - 1) {
      setCurrentVideoIndex(prev => prev + 1);
    }
  };

  const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div className="video-player-page">
      <Header title="Video Player" />
      <div className="video-player-content">
        <div className="video-player-container">
          <div className="video-player">
            <YouTube
              videoId={videos[currentVideoIndex].id}
              opts={opts}
              onEnd={handleEnd}
              onError={(error) => console.error('YouTube Player Error:', error)}
            />
          </div>
          <div className="playlist">
            <h3>Playlist</h3>
            <ul>
              {videos.map((video, index) => (
                <li
                  key={video.id}
                  className={`playlist-item ${index <= currentVideoIndex ? 'unlocked' : 'locked'} ${completedVideos.includes(index) ? 'completed' : ''}`}
                >
                  {index <= currentVideoIndex ? (
                    completedVideos.includes(index) ? (
                      <FaCheck className="icon" />
                    ) : null
                  ) : (
                    <FaLock className="icon" />
                  )}
                  <span>{video.title}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="suggested-videos">
          <h3>Suggested Videos</h3>
          <div className="suggested-videos-container">
            {suggestedVideos.map((suggestedVideo, index) => (
              <li key={index} className="grid-item">
                <VideoComponent data={suggestedVideo} />
              </li>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}