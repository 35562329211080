// Ebooks.js
import React from 'react';
import './Ebook.css';
import book1 from "../../utils/img/books/black-girls.jpeg";
import book2 from "../../utils/img/books/hairloss.jpg";
import book3 from "../../utils/img/books/milady.jpg";
import book4 from "../../utils/img/books/science-black-hair.jpeg";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const books = [
    {
        title: "It Ends with Us: A Novel (1)",
        author: "Colleen Hoover",
        rating: 4.5,
        reviews: 349308,
        price: "$10.98",
        image: book1
      },
      {
        title: "Good Energy: The Surprising Connection Between Metabolism and Limitless Health",
        author: "Casey Means",
        rating: 4.3,
        reviews: 827,
        price: "$21.02",
        image: book2,
      },
      {
        title: "It Starts with Us: A Novel (It Ends with Us)",
        author: "Colleen Hoover",
        rating: 4.7,
        reviews: 177729,
        price: "$10.98",
        image: book3,
      },
      {
        title: "Build A Million Dollar Beauty Business",
        author: "Colleen Hoover",
        rating: 4.7,
        reviews: 177729,
        price: "$10.98",
        image: book4
    }
];

const Ebooks = () => {
  const handleAddToCart = (book) => {
    alert(`${book.title} has been added to your cart!`);
  };

  return (
    <>
      <Header title="E-books Store" />
      <div className="bestsellers">
        <h2>Best Sellers</h2>
        <div className="book-container">
          {books.map((book, index) => (
            <div className="book-card" key={index}>
              <img src={book.image} alt={book.title} />
              <h3>{book.title}</h3>
              <p>{book.author}</p>
              <p>Rating: {book.rating} ⭐ ({book.reviews})</p>
              <p className="price">{book.price}</p>
              <button className="add-to-cart" onClick={() => handleAddToCart(book)}>
                Add to Cart
              </button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Ebooks;