import React, { useState } from 'react';
import './FAQ.css'; // Import the CSS file for styling
import SingleHeader from '../../components/Header/SingleHeader';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What type of products should I use for dry hair?",
      answer: "For dry hair, look for products that are moisturizing and nourishing. Ingredients like argan oil, shea butter, and hyaluronic acid can help add moisture and shine to your hair."
    },
    {
      question: "How often should I trim my hair?",
      answer: "It's generally recommended to trim your hair every 6-8 weeks to prevent split ends and maintain healthy growth. However, if you're trying to grow your hair out, you can extend the time between trims."
    },
    {
      question: "What’s the best way to prevent heat damage?",
      answer: "To prevent heat damage, use a heat protectant spray before styling, avoid using high heat settings, and give your hair a break from heat tools by opting for heatless styles."
    },
    {
      question: "How can I add volume to fine hair?",
      answer: "For fine hair, use volumizing shampoos and conditioners, and consider using a lightweight mousse or root-lifting spray. Additionally, blow-drying your hair upside down can help create extra volume."
    },
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
    <SingleHeader title="FAQ" />
    <div className="faq-container">
      <h2 className="faq-title">Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div
              className="faq-question"
              onClick={() => toggleAnswer(index)}
            >
              <h3>{faq.question}</h3>
              <span className="faq-toggle-icon">
                {activeIndex === index ? '-' : '+'}
              </span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default FAQ;
