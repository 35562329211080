import React from 'react';
import './Category.css';
import category1 from '../../../utils/img/Category/menu-book.svg';
import category2 from '../../../utils/img/Category/video-cat.svg';
import category3 from '../../../utils/img/Category/blog-cat.svg';
import category4 from '../../../utils/img/Category/product-cat-buz.svg';
import { useNavigate } from 'react-router-dom';

export default function Category() {
  const Navigate = useNavigate()
  return (
    <section className="category" aria-label="category">
      <div className="category__container">

        <p className="category__subtitle">Services</p>

        <h2 className="category__title">
         
         We <span className="category__highlight">teach </span> you about your hair so that you can make informed decisions.
        </h2>

        <p className="category__text">
          Browse through our service catalog to find out how        
        </p>

        <ul className="category__list">

          <li className="category__item">
            <div className="category__card">
              <div className="category__icon">
                <img src={category1} width="40" height="40" loading="lazy" alt="Online E-books" />
              </div>
              <h3 className="category__card-title">
                <a href="/Bookstore" className="category__link">Online E-books Store</a>
              </h3>
              <p className="category__card-text">
                Bringing you book guides with illustrations on how to apply it.
              </p>
              <span className="category__badge" onClick={() => Navigate("/Bookstore")}>7 Books</span>
            </div>
          </li>

          <li className="category__item">
            <div className="category__card">
              <div className="category__icon">
                <img src={category2} width="40" height="40" loading="lazy" alt="Video Lists" />
              </div>
              <h3 className="category__card-title">
                <a href="/videos" className="category__link">Video Playlists</a>
              </h3>
              <p className="category__card-text">
                Videos with clear and better visualization of how to fight bad hair
              </p>
              <span className="category__badge" onClick={() => Navigate("/videos")}>3 Courses</span>
            </div>
          </li>

          <li className="category__item">
            <div className="category__card">
              <div className="category__icon">
                <img src={category3} width="40" height="40" loading="lazy" alt="Blog posts" />
              </div>
              <h3 className="category__card-title">
                <a href="/blogposts" className="category__link">Blog posts</a>
              </h3>
              <p className="category__card-text">
                A free time to spend, Read our latest blogs about hair care
              </p>
              <span className="category__badge" onClick={() => Navigate("/blogposts")}>19 Blogs</span>
            </div>
          </li>

          <li className="category__item">
            <div className="category__card">
              <div className="category__icon">
                <img src={category4} width="40" height="40" loading="lazy" alt="Hair care" />
              </div>
              <h3 className="category__card-title">
                <a href="/Productstore" className="category__link">Hair Care Products</a>
              </h3>
              <p className="category__card-text">
                We recommend hair care products from trusted suppliers that you can buy from our site
              </p>
              <span className="category__badge" onClick={() => Navigate("/Productstore")}>31 Products</span>
            </div>
          </li>

        </ul>

      </div>
    </section>
  );
}
