import React from 'react';
import './Stat.css';

const statsData = [
  {
    value: '100+',
    text: 'Booking Requests',
    bgColor: 'hsla(170, 75%, 41%,0.1)', // example color, replace with your choice
  },
  {
    value: '90+',
    text: 'Happy Clients',
    bgColor: 'hsla(351, 83%, 61%,0.1)', // example color, replace with your choice
  },
  {
    value: '76+',
    text: 'Rate Meetings Completed',
    bgColor: 'hsla(260, 100%, 67%,0.1)', // example color, replace with your choice
  },
  {
    value: '100%',
    text: 'Satisfaction',
    bgColor: 'hsla(42, 94%, 55%,0.1)', // example color, replace with your choice
  },
];

const Stats = () => (
  <section className="section stats" aria-label="stats">
    <div className="container">
      <ul className="flex-list">
        {statsData.map((stat, index) => (
          <li key={index} className="flex-item">
            <div className="stats-card">
              <h3 className="card-title">{stat.value}</h3>
              <p className="card-text">{stat.text}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export default Stats;
