import React from 'react'
import Dash from './Dash'

export default function Dashboard() {
  return (
    <div>
        <Dash />
    </div>
  )
}
