import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

export default function BlogComponent({ id, imgSrc, title, excerpt }) {
    const navigate = useNavigate();

    const HandlereadMore = (id) => {
        navigate(`/Blogposts/${id}`);
    };


    const truncateExcerpt = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    return (
        <div className="blog-container-items">
            <img src={imgSrc} alt="Blogpost" />
            <div className="blog-container-desc">
                <h3>{title}</h3>
                <div dangerouslySetInnerHTML={{ __html: truncateExcerpt(excerpt, 12) }} style={{fontWeight:"400 !important", fontSize:16}} />
                <p className="blog-read-more" onClick={() => HandlereadMore(id)}>
                    Read more <FaChevronRight className='read-more-icon' />
                </p>
            </div>
        </div>
    );
}
