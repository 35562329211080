import React from 'react';
import './404Page.css'; // Import the external CSS file

const NotFoundPage = () => {
    const handleBackHome = () => {
        window.location.href = '/'; // Change to your home page URL
    };

    return (
        <div className="not-found-container">
            <div className="not-found-icon">⚠️</div>
            <h1 className="not-found-code">404</h1>
            <h2 className="not-found-title">Page Not Found</h2>
            <p className="not-found-message">
                We’re sorry, the page you have looked for does not exist in our website! 
                Maybe go to our home page or try to use a search?
            </p>
            <button className="back-home-button" onClick={handleBackHome}>
                Go Back To Home
            </button>
        </div>
    );
};

export default NotFoundPage;