import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../../utils/Firebase/firebase';
import './Signup.css'; // Import the CSS file
import ChildHeader from '../../constants/ChildHeader/ChildHeader';
import Footer from '../../components/Footer/Footer';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Save user data to db
      await setDoc(doc(db, 'users', user.uid), {
        username,
        email,
        phone
      });

      // Clear input fields or redirect as needed
      setEmail('');
      setPassword('');
      setUsername('');
      setPhone('');
      setError('');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
    <ChildHeader children="Sign Up" />
    <div className="signup-container">
      {error && <p className="signup-error">{error}</p>}
      <form onSubmit={handleSignup} className="signup-form">
        <label className="signup-label">
          Username:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="signup-input"
          />
        </label>
        <label className="signup-label">
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="signup-input"
          />
        </label>
        <label className="signup-label">
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="signup-input"
          />
        </label>
        <label className="signup-label">
          Phone Number:
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            className="signup-input"
          />
        </label>
        <button type="submit" className="signup-button">Sign Up</button>
      </form>
    </div>
    <Footer />
    </>
  );
};

export default Signup;
