import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../utils/Firebase/firebase";
import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { FaLinkedin, FaFacebook, FaInstagram, FaTiktok, FaTwitter } from "react-icons/fa";
import logo from "../../../utils/img/logo.png";
import "./Post.css";


export default function Post() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [relatedPosts, setRelatedPosts] = useState([]);

  useEffect(() => {
    const fetchRelatedPosts = async () => {
      try {
        const relatedPostsCollection = collection(db, "blogs");
        const q = query(relatedPostsCollection, orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);
        const relatedPostsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRelatedPosts(relatedPostsList);
      } catch (error) {
        console.error("Error fetching relatedPosts: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRelatedPosts();
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const docRef = doc(db, "blogs", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPost(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (!post) return <p>Post not found.</p>;

  const handleReadMore = (id) => {
    navigate(`/Blogposts/${id}`);
  };

  const truncateExcerpt = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const handleShareClick = (platform) => {
    const shareUrl = encodeURIComponent(window.location.href);
    const shareText = encodeURIComponent(post.title + " - " + truncateExcerpt(post.content, 20));

    let shareLink = '';

    switch(platform) {
      case 'Facebook':
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
        break;
      case 'Twitter':
        shareLink = `https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareText}`;
        break;
      case 'LinkedIn':
        shareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${post.title}&summary=${shareText}`;
        break;
      case 'Instagram':
        alert("Instagram does not support direct sharing via URL. Please use the mobile app.");
        return;
      case 'Tiktok':
        alert("TikTok does not support direct sharing via URL. Please use the mobile app.");
        return;
      default:
        return;
    }

    window.open(shareLink, '_blank');
  };

  return (
    <div>
      <Header title={post.title || "Post Title"} />
      <div className="post">
        <div className="post-container">
          <div className="post-image">
            {post.imageUrl ? (
              <img className="post-img" src={post.imageUrl} alt="Blog post" />
            ) : (
              <img className="post-img" src={logo} alt="Afriniceties logo" />
            )}
          </div>
          <div className="post-desc">
            <div className="post-date">
              <p>{new Date(post.createdAt.seconds * 1000).toLocaleDateString()}</p>
            </div>
            <div className="post-title">
              <p>{post.title}</p>
            </div>
            <div className="post-author-data">
              <h4>Written by</h4>
              <img src={logo} alt="Author" />
              <p>
                <span className="author-name">Elizabeth Primah</span>
                <span>
                  Hi, I'm Elizabeth, founder of Afroniceties. I’m a qualified
                  hair coach and a professional mentor-in-training for Natural
                  African hair care Systems.
                </span>
              </p>
            </div>
            <div className="post-details">
              <p style={{ marginBottom: 20 }}
                className="post-sample"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
            </div>
            <div className="bottom-icons">
              <h3>Share:</h3>
              <div className="share-icons">
                <span className="facebook" onClick={() => handleShareClick('Facebook')}>
                  <FaFacebook />
                </span>
                <span className="twitter" onClick={() => handleShareClick('Twitter')}>
                  <FaTwitter />
                </span>
                <span className="tiktok" onClick={() => handleShareClick('Tiktok')}>
                  <FaTiktok />
                </span>
                <span className="insta" onClick={() => handleShareClick('Instagram')}>
                  <FaInstagram />
                </span>
                <span className="linkedin" onClick={() => handleShareClick('LinkedIn')}>
                  <FaLinkedin />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="posts-rel">
          <h2 style={{ marginLeft: 15 }}>Related Articles</h2>
          <div className="posts-rel-container">
            <div className="posts-rel-items">
              {relatedPosts.map((relatedPost, index) => (
                <div key={index} className="posts-rl-item">
                  <img src={relatedPost.imageUrl || logo} alt="" />
                  <h5>{relatedPost.title}</h5>
                  <p
                    className="see-more-post-rel"
                    onClick={() => handleReadMore(relatedPost.id)}
                  >
                    See more
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
