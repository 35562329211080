// EditPost.js
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../utils/Firebase/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Footer from "../../../components/Footer/Footer";
import "./EditPost.css"
import SingleHeader from "../../../components/Header/SingleHeader";

export default function EditPost() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [post, setPost] = useState({ title: '', content: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const docRef = doc(db, "blogs", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setPost(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPost((prevPost) => ({ ...prevPost, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, "blogs", id);
      await updateDoc(docRef, post);
      navigate(`/Blogposts/${id}`); // Redirect after updating
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="edit-blog-post">
      <SingleHeader title="Edit Post" />
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="title"
          value={post.title}
          onChange={handleChange}
          placeholder="Title"
          required
        />
        <textarea
          name="content"
          value={post.content}
          onChange={handleChange}
          placeholder="Content"
          required
        />
        <button type="submit">Update Post</button>
      </form>
      <Footer />
    </div>
  );
}
