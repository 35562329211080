import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import scalpScrub from "../../utils/img/Hero/sephora.avif";
import asideimage from "../../utils/img/Products/Hairscience.webp";
import "./Products.css";
import ProductComponents from "../../components/ProductComponents/ProductComponents";


const productsData = [
    {
        id:1,
        imgSrc: scalpScrub,
        productTitle: "Cleansing Scalp Scrub",
        productPrice: "240,000"
    },
    {
        id:2,
        imgSrc: scalpScrub,
        productTitle: "Cleansing Scalp Scrub",
        productPrice: "240,000"
    },
    {
        id:3,
        imgSrc: scalpScrub,
        productTitle: "Cleansing Scalp Scrub",
        productPrice: "240,000"
    },
    {
        id:4,
        imgSrc: scalpScrub,
        productTitle: "Cleansing Scalp Scrub",
        productPrice: "240,000"
    },
]

export default function Products() {
  return (
    <div>
      <Header title="Products Shop" />
      <div className="products-sect">
        <div className="products-page">
          <div className="products-container" style={{flexWrap:"wrap", gap:15, width:"100%"}}>
          {productsData.map((product) => (
              <div key={product.id} className="product-item" style={{maxWidth:"45%"}}>
                <ProductComponents
                id={product.id}
                imgSrc={product.imgSrc}
                productTitle={product.productTitle}
                productPrice={product.productPrice}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="product-aside">
          <div className="product-aside-container">
            <div className="product-aside-items">
              <div className="cat-items">
                <h4>Categories</h4>
                <div className="cat-item-list">
                  <ul>
                    <li>Makeup</li>
                    <li>Lip stick</li>
                    <li>Face Powder</li>
                    <li>Eye liner</li>
                    <li>Foundation</li>
                    <li>Hair Gel</li>
                  </ul>
                </div>
              </div>
              <div className="cat-items">
                <h4>Popular product</h4>
                <div className="cat-product-list">
                  <div className="cat-produt-list-item">
                    <img src={asideimage} alt="Aside Product" />
                    <p>
                      <span className="aside-item-title">
                        K18 Biomimetic Hairscience
                      </span>
                      <span className="aside-item-price">UGX: 190,000</span>
                    </p>
                  </div>
                  <div className="cat-produt-list-item">
                    <img src={asideimage} alt="Aside Product" />
                    <p>
                      <span className="aside-item-title">
                        K18 Biomimetic Hairscience
                      </span>
                      <span className="aside-item-price">UGX: 190,000</span>
                    </p>
                  </div>
                  <div className="cat-produt-list-item">
                    <img src={asideimage} alt="Aside Product" />
                    <p>
                      <span className="aside-item-title">
                        K18 Biomimetic Hairscience
                      </span>
                      <span className="aside-item-price">UGX: 190,000</span>
                    </p>
                  </div>
                  <div className="cat-produt-list-item">
                    <img src={asideimage} alt="Aside Product" />
                    <p>
                      <span className="aside-item-title">
                        K18 Biomimetic Hairscience
                      </span>
                      <span className="aside-item-price">UGX: 190,000</span>
                    </p>
                  </div>
                  <div className="cat-produt-list-item">
                    <img src={asideimage} alt="Aside Product" />
                    <p>
                      <span className="aside-item-title">
                        K18 Biomimetic Hairscience
                      </span>
                      <span className="aside-item-price">UGX: 190,000</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="cat-items">
                <h4>Categories</h4>
                <div className="cat-item-list">
                  <ul>
                    <li>Makeup</li>
                    <li>Lip stick</li>
                    <li>Face Powder</li>
                    <li>Eye liner</li>
                    <li>Foundation</li>
                    <li>Hair Gel</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
