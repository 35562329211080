import React, { useState } from 'react';
import './Payment.css';

export default function Payment() {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [mobileOption, setMobileOption] = useState('');

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
    setMobileOption(''); // Reset mobile option when payment method changes
  };

  const handleMobileOptionChange = (e) => {
    setMobileOption(e.target.value);
  };

  return (
    <div className="payment-container">
      {/* <h2 className="payment-title">Select Payment Method</h2> */}
      <div className="payment-options">
        <label className="payment-option">
          <input
            type="radio"
            name="paymentMethod"
            value="creditCard"
            checked={paymentMethod === 'creditCard'}
            onChange={handlePaymentMethodChange}
          />
          Credit Card
        </label>
        <label className="payment-option">
          <input
            type="radio"
            name="paymentMethod"
            value="mobileMoney"
            checked={paymentMethod === 'mobileMoney'}
            onChange={handlePaymentMethodChange}
          />
          Mobile Money
        </label>
      </div>

      {paymentMethod === 'mobileMoney' && (
        <div className="mobile-options">
          <h3>Select Mobile Money Provider</h3>
          <label className="mobile-option">
            <input
              type="radio"
              name="mobileProvider"
              value="airtel"
              checked={mobileOption === 'airtel'}
              onChange={handleMobileOptionChange}
            />
            Airtel
          </label>
          <label className="mobile-option">
            <input
              type="radio"
              name="mobileProvider"
              value="mtn"
              checked={mobileOption === 'mtn'}
              onChange={handleMobileOptionChange}
            />
            MTN
          </label>
        </div>
      )}
      
      <button className="submit-button" disabled={!paymentMethod || (paymentMethod === 'mobileMoney' && !mobileOption)}>
        Proceed
      </button>
    </div>
  );
}
