import React, { useContext } from "react";
import { CartContext } from "../../utils/Context/BookContext";
import "./ProductComponent.css";

function ProductComponent({ product }) {
  const cart = useContext(CartContext);
  const productQuantity = cart.getProductQuantity(product.id);

  return (
    <div className="product-card">
      <div className="product-card-image">
        <img src={product.bookImg} alt={product.bookTitle} />
      </div>
      <div className="product-card-content">
        <h3 className="product-card-title">{product.bookTitle}</h3>
        <p className="product-card-price">{product.bookPrice}</p>
        {productQuantity > 0 ? (
          <div className="product-card-in-cart">
            <div className="product-card-quantity">
              <h4>In Cart: {productQuantity}</h4>
              <div className="product-card-buttons">
                <span
                  className="product-card-button add"
                  onClick={() => cart.addOneToCart(product.id)}
                >
                  +
                </span>
                <span
                  className="product-card-button remove"
                  onClick={() => cart.removeOneFromCart(product.id)}
                >
                  -
                </span>
              </div>
            </div>
            <button
              className="product-card-remove-btn"
              onClick={() => cart.deleteFromCart(product.id)}
            >
              Remove Book
            </button>
          </div>
        ) : (
          <button
            className="product-card-add-btn"
            onClick={() => cart.addOneToCart(product.id)}
          >
            Add to Cart
          </button>
        )}
      </div>
    </div>
  );
}

export default ProductComponent;
