import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../utils/Firebase/firebase"; 
import ProductComponent from "../../../Pages/BookComponent/ProductComponent";
import { useNavigate } from "react-router-dom";
import "./Products.css";

export default function Product() {
  const Navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const desiredCategory = "product";

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Set loading to true when starting to fetch
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const fetchedProducts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products: ", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchProducts();
  }, []);

  const filteredData = products.filter(
    (product) => product.category === desiredCategory
  ).slice(0, 4);

  function HandleMore() {
    Navigate("/Productstore");
  }

  return (
    <div className="products">
      <div className="products-header">
        <div className="header-content">
          <div className="top-head">
            <span className="title">Top Sales</span>
            <span className="see-more-btn" onClick={HandleMore}>
              See More
            </span>
          </div>
          <p>
            Discover our best-selling products of the season. These top-rated items have been carefully selected based on customer feedback and sales performance. Explore now to find your next favorite!
          </p>
        </div>
      </div>

      <div className="bookstore">
        <h1 className="store-title">Product Store</h1>
        <div className="book-list">
          {loading ? ( // Show loading indicator while fetching
            <p>Loading products...</p>
          ) : filteredData.length > 0 ? (
            filteredData.map((product) => (
              <ProductComponent key={product.id} product={product} />
            ))
          ) : (
            <p>No products available in this category.</p>
          )}
        </div>
      </div>
    </div>
  );
}
