import React from 'react';
import { useCart } from '../../../utils/Context/CartContext';  // Update the import path accordingly
import CartItem from './CartItem';
import CheckoutForm from './CheckoutForm';
import Footer from '../../../components/Footer/Footer';
import ProductComponents from '../../../components/ProductComponents/ProductComponents';
import "./Cart.css"
import Header from '../../../components/Header/Header';
import asideimage from "../../../utils/img/Products/Hairscience.webp";
import scalpScrub from "../../../utils/img/Hero/sephora.avif";



const Cart = () => {
  const productsData = [
    {
      id: 1,
      imgSrc: scalpScrub,
      productTitle: "Cleansing Scalp Scrub",
      productPrice: "240,000",
    },
    {
      id: 2,
      imgSrc: scalpScrub,
      productTitle: "Cleansing Scalp Scrub",
      productPrice: "240,000",
    },
    {
      id: 3,
      imgSrc: scalpScrub,
      productTitle: "Cleansing Scalp Scrub",
      productPrice: "240,000",
    },
    {
      id: 4,
      imgSrc: scalpScrub,
      productTitle: "Cleansing Scalp Scrub",
      productPrice: "240,000",
    },
  ];

  const { cart, setCart } = useCart();

  const handleRemoveItem = (itemId) => {
    setCart(prevCart => prevCart.filter(item => item.id !== itemId));
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    setCart(prevCart => 
      prevCart.map(item =>
        item.id === itemId ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  return (
    <div>
      <Header title="Shopping Cart" />
      <div className="cart-container">
        <div className='cart-container-items'>
          <h1 style={{textAlign:"center"}}>Shopping Cart</h1>
          {cart.length > 0 ? (
            <>
              {cart.map(item => (
                <CartItem
                  key={item.id}
                  item={item}
                  onRemove={handleRemoveItem}
                  onQuantityChange={handleQuantityChange}
                />
              ))}
              <div style={{ marginTop: '20px', textAlign:"center", marginBottom:"20px" }}>
                <h2>Total: ${calculateTotalPrice()}</h2>
              </div>
            </>
          ) : (
            <p style={{padding:15, textAlign:"center"}}>No items in cart</p>
          )}
          <CheckoutForm />
        </div>
        <div className="product-aside">
        <div className="product-aside-container">
            <div className="product-aside-items">
              <div className="cat-items">
                <h4>Categories</h4>
                <div className="cat-item-list">
                  <ul>
                    <li>Makeup</li>
                    <li>Lip stick</li>
                    <li>Face Powder</li>
                    <li>Eye liner</li>
                    <li>Foundation</li>
                    <li>Hair Gel</li>
                  </ul>
                </div>
              </div>
              <div className="cat-items">
                <h4>Popular product</h4>
                <div className="cat-product-list">
                  <div className="cat-produt-list-item">
                    <img src={asideimage} alt="Aside Product" />
                    <p>
                      <span className="aside-item-title">
                        K18 Biomimetic Hairscience
                      </span>
                      <span className="aside-item-price">UGX: 190,000</span>
                    </p>
                  </div>
                  <div className="cat-produt-list-item">
                    <img src={asideimage} alt="Aside Product" />
                    <p>
                      <span className="aside-item-title">
                        K18 Biomimetic Hairscience
                      </span>
                      <span className="aside-item-price">UGX: 190,000</span>
                    </p>
                  </div>
                  <div className="cat-produt-list-item">
                    <img src={asideimage} alt="Aside Product" />
                    <p>
                      <span className="aside-item-title">
                        K18 Biomimetic Hairscience
                      </span>
                      <span className="aside-item-price">UGX: 190,000</span>
                    </p>
                  </div>
                  <div className="cat-produt-list-item">
                    <img src={asideimage} alt="Aside Product" />
                    <p>
                      <span className="aside-item-title">
                        K18 Biomimetic Hairscience
                      </span>
                      <span className="aside-item-price">UGX: 190,000</span>
                    </p>
                  </div>
                  <div className="cat-produt-list-item">
                    <img src={asideimage} alt="Aside Product" />
                    <p>
                      <span className="aside-item-title">
                        K18 Biomimetic Hairscience
                      </span>
                      <span className="aside-item-price">UGX: 190,000</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="cat-items">
                <h4>Categories</h4>
                <div className="cat-item-list">
                  <ul>
                    <li>Makeup</li>
                    <li>Lip stick</li>
                    <li>Face Powder</li>
                    <li>Eye liner</li>
                    <li>Foundation</li>
                    <li>Hair Gel</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div className="single-post-rel">
          <h4 className="single-post-rel-title">Related Products</h4>
          <div className="products-container">
            {productsData.map((product) => (
              <div
                key={product.id}
                className="product-item"
                style={{ maxWidth: "45%" }}
              >
                <ProductComponents
                  id={product.id}
                  imgSrc={product.imgSrc}
                  productTitle={product.productTitle}
                  productPrice={product.productPrice}
                />
              </div>
            ))}
          </div>
        </div>
      <Footer />
    </div>
  );
};

export default Cart;
