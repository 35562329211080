import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import './Videohome.css';
import video1 from '../../../utils/img/Hero/video-thumb.png';
import video2 from '../../../utils/img/Hero/videoBanner.png';
import video3 from '../../../utils/img/Hero/VideoThumbnail.png';
import VideoComponent from './VideoComponent/VideoComponent';

const videos = [
  {
    src: video1,
    date: '17/Aug',
    category: 'Hair care',
    title: 'Taking Care of Yourself: It’s Not Just About You',
    rating: '5.0/7 Rating',
    topics: '3 Topics',
    views: '20 Views',
    alt: 'Taking Care of Yourself: It’s Not Just About You',
  },
  {
    src: video2,
    date: '18/Aug',
    category: 'Hair Loss',
    title: 'Explore the causes of hair loss and understand whether it’s stress-related or genetic.',
    rating: '4.5/9 Rating',
    topics: '5 Topics',
    views: '35 Views',
    alt: 'Explore the causes of hair loss',
  },
  {
    src: video3,
    date: '20/Aug',
    category: 'Hair Growth',
    title: 'Discover effective methods to promote faster and stronger hair growth.',
    rating: '4.9/7 Rating',
    topics: '4 Topics',
    views: '18 Views',
    alt: 'Faster and stronger hair growth.',
  },
];

const Videohome = () => (
  <section className="section course" id="courses" aria-label="course">
    <div className="container">
      <p className="section-subtitle">Popular Videos</p>
      <h2 className="section-title">Pick A Video To Get Started</h2>

      <ul className="grid-list">
        {videos.map((video, index) => (
          <li key={index} className="grid-item">
            <VideoComponent data={video} />
          </li>
        ))}
      </ul>

      <a href="/videos" className="btn">
        <span className="btn-text">Browse more Videos</span>
        <FaArrowRight />
      </a>
    </div>
  </section>
);

export default Videohome;
