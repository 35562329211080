// src/BlogEditor.js
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { db, storage } from '../../../utils/Firebase/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './BlogEditor.css'; // Import CSS for styling
import Footer from '../../../components/Footer/Footer';
import SingleHeader from '../../../components/Header/SingleHeader';

const BlogEditor = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [editorContent, setEditorContent] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEditorChange = (value) => {
    setEditorContent(value);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      // Handle image upload
      let imageUrl = '';
      if (image) {
        const imageRef = ref(storage, `images/blog/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      // Save post to Firestore
      await addDoc(collection(db, 'blogs'), {
        title: data.title,
        content: editorContent,
        imageUrl,
        createdAt: new Date(),
      });

      alert('Blog post saved!');
      setEditorContent('');
      setImage(null);
      setValue('title', '');
    } catch (error) {
      console.error('Error adding document: ', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <SingleHeader title="Create New blog" />
    <div className="blog-editor">
      <h1>Write a New Blog Post</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input id="title" name="title" type="text" {...register('title')} required />
        </div>
        <div className="form-group">
          <label htmlFor="content">Content</label>
          <ReactQuill value={editorContent} onChange={handleEditorChange} placeholder='Start writing...'/>
        </div>
        <div className="form-group">
          <label htmlFor="image">Image</label>
          <input
            id="image"
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>
        <button type="submit" className='savev-post-btn' disabled={loading}>
          {loading ? 'Saving...' : 'Save Post'}
        </button>
      </form>
    </div>
    <Footer />
    </>
  );
};

export default BlogEditor;
