import React, { useEffect, useState } from "react";
import { db } from "../../utils/Firebase/firebase"; 
import { collection, getDocs, query, where } from "firebase/firestore";
import "./BookStore.css";
import Navbar from "../../components/Navbar/Navbar";
import ProductComponent from "../BookComponent/ProductComponent";

export default function ProductsStore() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Initial loading state
  const desiredCategory = "product";

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Start loading when the fetch begins
      try {
        const q = query(
          collection(db, "products"),
          where("category", "==", desiredCategory)
        );
        const querySnapshot = await getDocs(q);
        const productsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products: ", error);
      } finally {
        setLoading(false); // Stop loading once fetch is done
      }
    };

    fetchProducts();
  }, []);

  return (
    <>
      <Navbar />
      <div className="bookstore">
        <h1 className="store-title">Products Store</h1>
        <div className="book-list">
          {loading ? (
            <p>Loading...</p> // Show loading message while fetching
          ) : products.length > 0 ? (
            products.map((product) => (
              <ProductComponent key={product.id} product={product} />
            ))
          ) : (
            <p>No products available in this category.</p>
          )}
        </div>
      </div>
    </>
  );
}
