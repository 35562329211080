import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyDrGO7u5PQbZb_b86gy93kZNmZPmpbWnSE",
    authDomain: "afroniceties-ug.firebaseapp.com",
    projectId: "afroniceties-ug",
    storageBucket: "afroniceties-ug.appspot.com",
    messagingSenderId: "776846986326",
    appId: "1:776846986326:web:4e5638b0022bfc9d6f7ff7",
    measurementId: "G-90WYMHXQ4B"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
export { db, storage, auth };
