// Blogposts.jsx
import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../../../utils/Firebase/firebase'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';
import './Blogposts.css';
import { FaArrowRight } from 'react-icons/fa';


export default function Blogposts() {
  const [blogs, setBlogs] = useState([]);
  const Navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogsQuery = query(
          collection(db, 'blogs'),
          orderBy('createdAt', 'desc'),
          limit(3)
        );

        const snapshot = await getDocs(blogsQuery);
        const blogData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setBlogs(blogData);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();

    // Set up a real-time listener
    const unsubscribe = onSnapshot(
      query(
        collection(db, 'blogs'),
        orderBy('createdAt', 'desc'),
        limit(3)
      ),
      snapshot => {
        const blogData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setBlogs(blogData);
      },
      error => {
        console.error('Error with real-time listener:', error);
      }
    );

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, []);

  const truncateExcerpt = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };
  const HandleReadmore = (id) =>{
    Navigate(`/Blogposts/${id}`)
  }


  return (
    <div className="blogposts">
      <div className="blog-home-header">
      <div className="blog-home-header-content">
      <h4>
      Unlock the Secrets of Radiant Natural African Hair
      </h4>
      <p>
      Discover tips and tricks for stunning, healthy curls. Dive in to elevate your natural hair game with expert advice and must-try styles!
      </p>
      </div>
      </div>
      <div className="blog-container">
        {blogs.length === 0 ? (
          <p>Loading Blog posts</p>
        ) : (
          blogs.map(blog => (
            <div className="blog" key={blog.id}>
              <img src={blog.imageUrl} alt={blog.title} onClick={() => HandleReadmore(blog.id)}/>
              <div className="blog-desc">
                <h4>{blog.title}</h4>
                <div style={{fontSize:18, fontWeight:"500"}} dangerouslySetInnerHTML={{ __html: truncateExcerpt(blog.content, 12) }} />
                <span className="more" onClick={() => HandleReadmore(blog.id)}>Read more...</span>
              </div>
            </div>
          ))
        )}
      </div>
      {/* <div className="blog-home-header-see-more">
      <a href="/Blogposts">See All</a>
      </div> */}
      <a href="/blogposts" className="btn">
        <span className="btn-text">Browse more Blogs</span>
        <FaArrowRight />
      </a>
    </div>
  );
}
