import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import About from '../screens/Home/About/About'
import Blogposts from '../screens/Home/Blogposts/Blogposts'
import Product from '../screens/Home/Products/Product'
import Footer from '../components/Footer/Footer'
import Newsletter from '../components/Newsletter/Newsletter'
import Calltoaction from '../screens/Home/Cta/Calltoaction'
import TestimonialSection from '../screens/Home/Testimonial/TestimonialSection'
import Hero from '../screens/Home/Hero/Hero'
import Category from '../screens/Home/Category/Category'
import Videohome from '../screens/Home/Videos/Videohome'
import Stats from '../screens/Home/Stats/Stats'
import EbookComponent from '../screens/Home/EbookComponent/EbookComponent'

export default function Layout() {
  return (
    <div>
        <Navbar />
        <Hero />
        <Category />
        <About />
        <EbookComponent />
        <Blogposts />
        <Product />
        <Videohome />
        <Calltoaction />
        <Stats />
        <TestimonialSection />
        <Newsletter />
        <Footer />
    </div>
  )
}
