import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from "./constants/Home";
import Header from "./components/Header/Header";
import Bloghome from "./screens/Blog/Bloghome";
import Post from "./screens/Blog/Post/Post";
import Products from "./screens/Products/Products";
import Singleproduct from "./screens/Products/Singleproduct/Singleproduct";
import Cart from "./screens/Products/Cart/Cart";
import Booking from "./screens/Booking/Booking";
import Videolists from "./screens/Video/Videolists";
import VideoPlayer from "./screens/Video/VideoPlayer";
import NotFoundPage from "./components/Error/NotFoundPage";
import BlogEditor from "./screens/Blog/BlogEditor/BlogEditor";
import Login from "./Auth/Signin/Login";
import Signup from "./Auth/Signup/Signup";
import { UserAuthContextProvider } from "./constants/UserAuth/UserAuth";
import { auth } from "./utils/Firebase/firebase";
import Payment from "./components/Payment/Payment";
import Ebooks from "./screens/Ebook/Ebook";
import BookDetails from "./screens/Ebook/BookDetails/BookDetails";
import BookStore from "./Pages/ProductPages/BookStore";
import CartBook from "./Pages/BookCart/CartBook";
import BookCartProvider from "./utils/Context/BookContext";
import ProductsStore from "./Pages/ProductPages/ProductsStore";
import About from "./screens/About/About";
import AddBook from "./screens/Ebook/AddBook/AddBook";
import AddProduct from "./screens/Products/AddProduct/AddProduct";
import FAQ from "./screens/FAQ/FAQ";
import ContactForm from "./screens/ContactForm/ContactForm";
import Dashboard from "./utils/Admin/Dashboard";
import { db } from "./utils/Firebase/firebase"; 
import { collection, doc, setDoc, increment } from 'firebase/firestore';
import CreateDash from "./utils/Admin/components/CreateDash";
import PaymentComponent from "./components/Payment/PaymentComponent";
import Dashboardposts from "./utils/Admin/components/Dashboardposts";
import EditPost from "./screens/Blog/Post/EditPost";
import DashboardProducts from "./utils/Admin/components/DashboardProducts";
import CheckoutCheck from "./Pages/BookCart/CheckoutCheck.jsx";


function App() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const trackVisit = async () => {
      const visitsRef = collection(db, 'visits');
      const now = new Date();
      const timestamp = now.toISOString().split('T')[0]; // Format date as YYYY-MM-DD

      // Increment visit count for the current date
      await setDoc(doc(visitsRef, timestamp), {
        date: timestamp,
        count: increment(1),
      }, { merge: true });
    };

    trackVisit();
  }, []);
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);
  
  return (
    <BookCartProvider>
    <UserAuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/Books' element={<Ebooks />} />
          <Route path='/header' element={<Header />} />
          <Route path='/signup' element={user ? <Navigate to="/" /> : <Signup />} />
          <Route path='/Signin' element={user ? <Navigate to="/" /> : <Login />} />
          <Route path='/blogposts' element={<Bloghome />} />
          <Route path='/shop' element={<Products />} />
          <Route path='/PayAfro' element={<PaymentComponent />} />
          <Route path='/Bookcart' element={<CartBook />} />
          <Route path='/Createbook' element={<AddBook />} />
          <Route path='/Createproduct' element={<AddProduct />} />
          <Route path='/About-us' element={<About />} />
          <Route path='/Productstore' element={<ProductsStore />} />
          <Route path='/Books/book' element={<BookDetails />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/Checkoutcheck' element={<CheckoutCheck />} />
          <Route path='/FAQ' element={<FAQ />} />
          <Route path='/Contact-us' element={<ContactForm />} />
          <Route path='/Payment' element={<Payment />} />
          <Route path='/Bookstore' element={<BookStore />} />
          <Route path='/Adminproducts' element={<DashboardProducts />} />
          <Route path='/bookings' element={<Booking />} />
          <Route path='/createblog' element={<BlogEditor />} />
          <Route path='/Admin' element={<Dashboard />} />
          <Route path='/videos' element={<Videolists />} />
          <Route path='/Adminblogs' element={<Dashboardposts />} />
          <Route path='/Createall' element={<CreateDash />} />
          <Route path="/videos/:videoId" element={<VideoPlayer />} />
          <Route path='/cart/:id' element={<Cart />} />
          <Route path='/blogposts/:id' element={<Post />} />
          <Route path="/Blogposts/edit/:id" element={<EditPost />} />
          <Route path='/shop/:id' element={<Singleproduct />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </UserAuthContextProvider>
    </BookCartProvider>
    
  );
}

export default App;
