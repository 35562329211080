import React from "react";
import "./Newsletter.css";

export default function Newsletter() {
  return (
    <section className="latest-newsletter__area">
      <div className="nl-container">
        <div className="nl-row">
          <div className="latest-newsletter__content">
            <div className="nl-header">
                <div className="nl-header-content">
                    <h2 className="nl-title">
                    Subcribe To Our Newsletter
                    </h2>
                    <p className="nl-title">
                    Be the first to receive expert tips on natural hair care, product recommendations, exclusive content, and updates on upcoming workshops and events. Join our community of women embracing the beauty of their natural hair and never miss out on the latest in hair care trends. Subscribe today and take your hair journey to the next level!
                    </p>
                </div>
            </div>
            <div className="nl-search">
              <input type="email" placeholder="Your Email" />
              <button type="submit" className="nl-btn">
                Signup
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
