import { db } from "../../utils/Firebase/firebase"; 
import { collection, getDocs } from 'firebase/firestore';

let BooksData = [];

async function fetchBooksData() {
    const booksCollection = collection(db, 'products');
    const bookSnapshot = await getDocs(booksCollection);
    BooksData = bookSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));
    return BooksData; // Return the fetched data
}

function getBookData(id) {
    const bookData = BooksData.find(book => book.id === id);
    if (!bookData) {
        console.log("No such book with ID: " + id);
        return undefined;
    }
    return bookData;
}

// Immediately invoke fetchBooksData and handle it properly
fetchBooksData().then(data => {
    console.log("Product data loaded:", data);
}).catch(error => {
    console.error("Error fetching books data:", error);
});

export { BooksData, getBookData, fetchBooksData };
