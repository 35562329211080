import React from 'react'
import "./Calltoaction.css"
import { useNavigate } from 'react-router-dom'



export default function Calltoaction() {
  const Navigate = useNavigate()
  const HandleBook = () =>{
          Navigate("/Bookings")
      }
  return (
    <div className="cta">
        <div className="cta-container">
            <h2>Hair that stuns, confidence that soars</h2>
            <p>We bring together the best of expert hair science and styling, empowerment coaching and nourishing natural products, to help you get the best out of your hair, everyday.</p>
            <button onClick={HandleBook}>Book Now</button>
        </div>
    </div>
  )
}
