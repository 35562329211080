import React, { useState } from 'react';
import axios from 'axios';

function PaymentComponent() {
    const [amount, setAmount] = useState('');
    const [description, setDescription] = useState('');
    // const [type, setType] = useState('MERCHANT');
    const [reference, setReference] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const paymentData = { amount, description, reference, first_name, last_name, email, phoneNumber };
        
        const response = await axios.post('http://localhost:5000/api/payment/initiate', paymentData);
        window.location.href = response.data.iframeSrc; // Redirect to PesaPal iframe
    };

    return (
        <div>
            <h1>PesaPal Payment Integration</h1>
            <form onSubmit={handleSubmit}>
                <input type="text" placeholder="Amount" onChange={(e) => setAmount(e.target.value)} required />
                <input type="text" placeholder="Description" onChange={(e) => setDescription(e.target.value)} required />
                <input type="text" placeholder="Reference" onChange={(e) => setReference(e.target.value)} required />
                <input type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} />
                <input type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} />
                <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
                <input type="text" placeholder="Phone Number" onChange={(e) => setPhoneNumber(e.target.value)} />
                <button type="submit">Pay Now</button>
            </form>
        </div>
    );
}

export default PaymentComponent;