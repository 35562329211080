import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import './Bloghome.css';
import { db } from '../../utils/Firebase/firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';


// Blog images
import blogImage from '../../utils/img/Blog/bloghome.jpg';
import blog1 from '../../utils/img/Blog/blog1.webp';
import blog2 from '../../utils/img/Blog/blog2.webp';
import blog3 from '../../utils/img/Blog/blog3.webp';
import Footer from '../../components/Footer/Footer';
import BlogComponent from './BlogComponent/BlogComponent';

// Blog data
const blogData = [
  {
    id:1,
    imgSrc: blogImage,
    title: "The Empowerment of Women’s Hair: Style to Self-Expression – Sinead O’Conner",
    excerpt: "Hair has always been a significant aspect of a woman’s appearance and identity. Serving as a powerful tool for self-expression, empowerment, and embracing individuality. Over",
  },
  {
    id:2,
    imgSrc: blog1,
    title: "Taming the Tresses: Problem-Solving Challenges with Thick Hair",
    excerpt: "Taming the Tresses: Problem-Solving Challenges with Thick Hair Introduction: As a seasoned hairdresser, I’ve had the privilege of working with diverse hair types, but one",
  },
  {
    id:3,
    imgSrc: blog2,
    title: "Fine Hair: How to Make It Great; 10 Top Tips",
    excerpt: "Most clients with fine hair are usually very adept at making the most of their hair. Why would that be do you think? When you",
  },
  {
    id:4,
    imgSrc: blog3,
    title: "What Are Parabens and Why Are They in Hair Products Anyway?",
    excerpt: "‘Paraben free’, you’ve likely become familiar seeing this term used by companies marketing to consumers looking for less chemicals in their environment and life in",
  },
];

export default function Bloghome() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsCollection = collection(db, 'blogs');
        const q = query(postsCollection, orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        const postsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPosts(postsList);
      } catch (error) {
        console.error('Error fetching posts: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  // console.log(posts[0])
  if (loading) return <p>Loading...</p>;  
  
  return (
    <div className="blog-home">
      <Header title="Blog Posts" />
      <div className="bloghome-section">
        <div className="section-header">
          <h4>Discover</h4>
          <p>Learn how to unlock the secret to beautiful hair</p>
        </div>
        <div className="bloghome-articles">
        {posts.length > 0 ? (
          <div className="bloghome-container">
            {posts.map((blog) => (
              <div key={blog.id} className="bloghome-container-item">
                <BlogComponent
                  id={blog.id}
                  imgSrc={blog.imageUrl}
                  title={blog.title}
                  excerpt={blog.content}
                />
              </div>
            ))}
          </div>
          ) : (
            <div className='bloghome-container'>No blog posts available.</div>
          )}
          <div className="bloghome-aside">
            <div className="blog-aside-container">
              <div className="blog-aside-items">
                <h4>Most Popular</h4>
                <div className="blog-aside-items-content">
                  {blogData.map((blog, index) => (
                    <div key={index} className="blog-aside-item">
                      <h5>{blog.title}</h5>
                      <p className="see-more-aside">See more</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
