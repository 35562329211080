import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../Firebase/firebase'; 
import { useNavigate } from 'react-router-dom';
import './DashboardPosts.css';  // Import the CSS file
import SingleHeader from '../../../components/Header/SingleHeader';

export default function DashboardPosts() {
  const Navigate = useNavigate();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const querySnapshot = await getDocs(collection(db, "blogs")); // Adjust the collection name as needed
      const postsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPosts(postsList);
    };

    fetchPosts();
  }, []);

  const handleEdit = (id) => {
    // Handle the edit logic here
    console.log('Edit post with ID:', id);
    Navigate(`/Blogposts/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "blogs", id)); // Delete the post from Firestore
      setPosts(posts.filter(post => post.id !== id)); // Remove the post from the local state
    } catch (error) {
      console.error("Error deleting post: ", error);
    }
  };

  const truncateHtmlContent = (htmlContent, maxWords) => {
    const div = document.createElement('div');
    div.innerHTML = htmlContent;
    const textContent = div.textContent || div.innerText || '';
    const words = textContent.split(' ');
    if (words.length <= maxWords) return htmlContent;
    const truncatedText = words.slice(0, maxWords).join(' ') + '...';
    div.innerHTML = truncatedText;
    return div.innerHTML;
  };

  return (
    <>
      <SingleHeader title="Dashboard Posts" />
      <div className="dashboard-posts-container">
        <ul>
          {posts.map(post => (
            <li key={post.id}>
              <h2>{post.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: truncateHtmlContent(post.content, 50) }} />
              <button onClick={() => handleEdit(post.id)}>Edit</button>
              <button onClick={() => handleDelete(post.id)}>Delete</button> {/* Delete button */}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
