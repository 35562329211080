import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase/firebase'; 
import './CSS/DashboardProducts.css'; // Import the CSS file
import SingleHeader from '../../../components/Header/SingleHeader';

export default function DashboardProducts() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const productSnapshot = await getDocs(productsCollection);
        const productList = productSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productList);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <>
    <SingleHeader title="Product List" />
    <div className="product-container-admin">
      <div className="product-grid-admin">
        {products.map(product => (
          <div className="product-card-admin" key={product.id}>
            <img className="product-image-admin" src={product.bookImg} alt={product.bookTitle} />
            <h3 className="product-title-admin">{product.bookTitle}</h3>
            <p className="product-desc-admin">{product.bookDesc}</p>
            <p className="product-price-admin">Price: UGX{product.bookPrice}</p>
          </div>
        ))}
      </div>
    </div>
    </>
  );
}
