// BookingCalendar.js
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { db } from '../../Firebase/firebase';
import { collection, getDocs } from 'firebase/firestore';
import './CSS/BookingCalendar.css';

const BookingCalendar = () => {
  const [date, setDate] = useState(new Date());
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'bookings')); // Adjust the collection name
        const bookingsData = querySnapshot.docs.map(doc => doc.data());
        console.log(bookingsData)

        const formattedBookings = bookingsData.map(booking => {
          return {
            ...booking,
            start: new Date(booking.start.seconds * 1000),
            end: new Date(booking.end.seconds * 1000)
          };
        });

        setBookings(formattedBookings);
      } catch (error) {
        console.error("Error fetching bookings: ", error);
      }
    };

    fetchBookings();
  }, []);

  const handleDateChange = newDate => {
    setDate(newDate);
  };

  const getTileContent = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = date.toISOString().split('T')[0];
      const bookingsForDate = bookings.filter(booking => {
        const bookingDate = booking.start.toISOString().split('T')[0];
        return bookingDate === formattedDate;
      });
      return bookingsForDate.length > 0 ? <div className="booking-marker">Booking</div> : null;
    }
  };

  return (
    <div className="calendar-container">
      <Calendar
        onChange={handleDateChange}
        value={date}
        tileContent={getTileContent}
      />
    </div>
  );
};

export default BookingCalendar;
