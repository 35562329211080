import React from "react";
import { CartContext } from "../../utils/Context/BookContext";
import { useContext } from "react";

export default function BookComponent({ book }) {
  const cart = useContext(CartContext);
  const productQuantiy = cart.getProductQuantity(book.id)
  return (
    <div className="book">
      <img src={book.bookImg} alt={book.bookTitle} className="book-img" />
      <h3 className="book-title">{book.bookTitle}</h3>
      <p className="book-price">UGX{(book.bookPrice / 100).toFixed(2)}</p>
      {
        productQuantiy > 0 ?
        <>
        <div className="in-cart-container">
          <div className="incart-header">
            <h4>In Cart: {productQuantiy}</h4>
            <div className="icart-btns">
              <p style={{marginTop:20}}>
                <span className="add" style={{fontSize:22, cursor:"pointer", fontWeight:"500", padding:"0px 10px", margin:5, backgroundColor:"#fff", border: "1px solid #000"}} onClick={() => cart.addOneToCart(book.id)}>+</span>
                <span className="remove" style={{fontSize:22, cursor:"pointer", fontWeight:"500", padding:"0px 10px", margin:5, backgroundColor:"#fff", border: "1px solid #000"}} onClick={() => cart.removeOneFromCart(book.id)}>-</span>
              </p>
            </div>
            <div className="delete-items" style={{backgroundColor:"tomato", color:"#fff", marginTop:10, padding:"4px 0px", cursor:"pointer"}} onClick={() => cart.deleteFromCart(book.id)}>Remove Book</div>
          </div>
        </div>
        </>
        :
      <button className="add-to-cart-btn" onClick={() => cart.addOneToCart(book.id)}>Add to Cart</button>
      }
    </div>
  );
}
