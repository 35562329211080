import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../../utils/Context/BookContext';
import CartProductComponent from '../BookComponent/CartProductComponent';
import './CartBook.css';
import Footer from '../../components/Footer/Footer';
import SingleHeader from '../../components/Header/SingleHeader';
import axios from 'axios';
import { XMLParser } from 'fast-xml-parser';

export default function CartBook() {
    const cart = useContext(CartContext);
    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(false); // Add loading state
    const date = new Date(); // Set initial date to today
    const PaynowURL = "https://secure.3gdirectpay.com/payv2.php?ID=";

    useEffect(() => {
        setAmount(cart.getTotalCost().toFixed(2));
    }, [cart.items]);

    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when payment starts

        const parsedAmount = parseFloat(amount);
        if (isNaN(parsedAmount) || parsedAmount <= 0) {
            alert("Please enter a valid positive number for the amount.");
            setLoading(false); // Reset loading state
            return;
        }

        const formattedAmount = parsedAmount.toFixed(4);
        const formattedDate = formatDate(new Date(date));
        if (!formattedDate) {
            alert("Please enter a valid date in the format YYYY-MM-DD.");
            setLoading(false); // Reset loading state
            return;
        }

        const paymentData = {
            paymentAmount: formattedAmount,
            serviceDescription: "shop with confidence. Elevate your online experience today!",
            serviceDate: formattedDate,
        };

        try {
            const response = await axios.post("https://afroniceties-backend.onrender.com/api/payment", paymentData);
            const parser = new XMLParser();
            const result = parser.parse(response.data);
            const transToken = result.API3G.TransToken;

            window.location.href = `${PaynowURL}${transToken}`;
        } catch (error) {
            console.error("Error sending payment data:", error);
            alert("An error occurred while processing your payment. Please try again.");
        } finally {
            setLoading(false); // Reset loading state in case of error or success
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return null;
        return date.toISOString().split("T")[0]; // Gets YYYY-MM-DD
    };

    return (
        <>
            <SingleHeader title="Shopping Cart" />
            <div className="cart-book">
                <div className="cart-items">
                    {productsCount > 0 ? (
                        <>
                            <p className="cart-items-title">Items in your cart:</p>
                            {cart.items.map((currentProduct, id) => (
                                <CartProductComponent key={id} id={currentProduct.id} quantity={currentProduct.quantity} />
                            ))}
                            <h1 className="cart-total">Total UGX: {amount}</h1>
                            <button className="proceed-button" onClick={handleSubmit} disabled={loading}>
                                {loading ? 'Processing...' : 'Proceed'}
                            </button>
                        </>
                    ) : (
                        <h1 className="empty-cart-message">Add something to cart</h1>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
}
