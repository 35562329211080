import React from 'react'
import "./ProductComponent.css"
import { FaHeart, FaStar } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';


export default function ProductComponents({ id, imgSrc, productTitle, productPrice }) {
    const Navigate = useNavigate();
    const HandleAddCart = (id) =>{
      Navigate(`/Cart/${id}`)
    }
    const Handleproduct = (id) => {
        Navigate(`/Shop/${id}`);
    };
  return (
    <div className="product-item">
        <div className="product-image">
          <img src={imgSrc} alt="" onClick={() => Handleproduct(id)} />
          <span>
            <FaHeart title="Add to Favourite" />
          </span>
        </div>
        <div className="product-desc">
          <h4>{productTitle}</h4>
          <div className="product-stars">
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
          </div>
          <div className="product-price">
            <p>{productPrice}</p>
          </div>
          <div className="product-btn" onClick={() => HandleAddCart(id)}>
            <p>Add to cart</p>
          </div>
        </div>
      </div>
  )
}