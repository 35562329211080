import React, { useState } from 'react';

const CheckoutForm = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [name, setName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle payment submission logic here
    console.log('Payment Details:', { cardNumber, expiryDate, cvv, name });
  };

  const formStyle = {
    backgroundColor: '#F9F9F9',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    margin: 'auto',
  };

  const inputStyle = {
    border: '1px solid #E0C184',
    borderRadius: '4px',
    padding: '12px',
    marginBottom: '16px',
    width: '100%',
  };

  const buttonStyle = {
    backgroundColor: '#623B6F',
    color: '#FFFFFF',
    border: 'none',
    padding: '12px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '100%',
  };

  return (
    <div style={formStyle}>
      {/* <h2>Checkout</h2> */}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Name on Card"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={inputStyle}
          required
        />
        <input
          type="text"
          placeholder="Card Number"
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
          style={inputStyle}
          required
        />
        <input
          type="text"
          placeholder="Expiry Date (MM/YY)"
          value={expiryDate}
          onChange={(e) => setExpiryDate(e.target.value)}
          style={inputStyle}
          required
        />
        <input
          type="text"
          placeholder="CVV"
          value={cvv}
          onChange={(e) => setCvv(e.target.value)}
          style={inputStyle}
          required
        />
        <button type="submit" style={buttonStyle}>
          Pay Now
        </button>
      </form>
    </div>
  );
};

export default CheckoutForm;
