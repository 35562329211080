import React from "react";
import Layout from "./components/Layout";
import Analytics from "./components/Analytics";

export default function Dash() {
  return (
    <div>
      <Layout>
        <Analytics />
      </Layout>
    </div>
  );
}
