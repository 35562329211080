import React from 'react'
import logo from "../../img/logo-03.png"
import { FaBookmark, FaBraille, FaCog, FaPencilAlt } from 'react-icons/fa'
import "./CSS/SideNav.css"

export default function SideNav() {
  return (
    <div className='side-nav-sect'>
        <div className="side-nav-container">
            <div className="side-nav-items">
                <div className="side-logo">
                    <img src={logo} alt="Afroniceties" />
                </div>
                <div className="main-dash">
                    <p>
                        <FaBraille />
                        <span>Dashboard</span>
                    </p>
                </div>
                <div className="main-dash">
                    <p>
                        <FaPencilAlt />
                        <span>Create</span>
                    </p>
                </div>
                <div className="main-dash">
                    <p>
                        <FaBookmark />
                        <span>Bookings</span>
                    </p>
                </div>
                <div className="main-dash">
                    <p>
                        <FaCog />
                        <span>Settings</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}
