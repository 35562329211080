import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../Firebase/firebase';  
import '../CSS/DashboardProducts.css'; // Import the CSS file

export default function Productdata() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const productSnapshot = await getDocs(productsCollection);
        const productList = productSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productList);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <>
      <div className="product-container-admin">
        <h2>Product List</h2>
        <div className="product-grid-admin">
          {products.slice(0, 10).map(product => ( // Only display the first 10 products
            <div className="product-card-admin" key={product.id}>
              {/* <img className="product-image-admin" src={product.bookImg} alt={product.bookTitle} /> */}
              <p className="product-title-admin">{product.bookTitle}</p>
              {/* <p className="product-desc-admin">{product.bookDesc}</p> */}
              <p className="product-price-admin">Price: UGX{product.bookPrice}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
