import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <h1 style={{ color: "#fff" }}>Welcome to Afroniceties</h1>
        <p>
        Uganda's premier, leading tech-driven natural hair literacy platform dedicated to empowering African women by providing personalized education and innovative solutions for their natural hair care needs.
        </p>
        <button className="cta-button">Start Your Journey</button>
      </div>
    </div>
  );
};

export default Hero;
