import React from 'react'
import { FaBookmark, FaEye, FaStar } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';

export default function VideoComponent({data}) {
    const navigate = useNavigate();

  const handleVideoClick = (videoId) => {
    navigate(`/videos/${videoId}`);
  };

  return (
    <div className="course-card">
              <figure className="card-banner">
                <img
                  src={data.src}
                  width="370"
                  height="220"
                  loading="lazy"
                  alt={data.alt}
                  className="img-cover" onClick={() =>handleVideoClick(data.id)}
                />
              </figure>
              <div className="card-content">
                <span className="badge">{data.category}</span>
                <h3 className="card-title">
                  <span>{data.title}</span>
                </h3>
                <div className="rating">
                  <div className="rating-stars">
                    {[...Array(5)].map((_, i) => (
                      <FaStar key={i} />
                    ))}
                  </div>
                  <p className="rating-text">({data.rating})</p>
                </div>
                <ul className="card-meta">
                  <li className="card-meta-item">
                    <span className="icon">
                      <FaBookmark />
                    </span>
                    <span className="meta-text">{data.topics}</span>
                  </li>
                  <li className="card-meta-item">
                    <span className="icon">
                      <FaEye />
                    </span>
                    <span className="meta-text">{data.views}</span>
                  </li>
                </ul>
              </div>
            </div>
  )
}
