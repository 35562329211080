import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import About1 from '../../utils/img/Hero/About-us.png';
import TestimonialSection from '../../screens/Home/Testimonial/TestimonialSection';
import './About.css';

export default function About() {
  return (

    <div>
      <Header title="About Us" />
      <div className="about-container">
        <section className="about-us-section">
          <div className="about-us-item">
            <div className="about-us-img">
              <img src={About1} alt="About Us" />
            </div>
            <div className="about-us-desc">
              <h2>About Us</h2>
              <p>
                Welcome to Afroniceties, a premier Ugandan natural hair literacy hub dedicated to celebrating and nurturing natural hair in all its forms, lengths, shapes and sizes. Our mission is to empower individuals with the knowledge and skills needed to care for their natural hair through personalized coaching sessions, comprehensive regimen building, and a range of tailored services. Whether you are transitioning to natural hair, seeking to maintain healthy curls, or looking for expert advice on natural hair care products, we are here to support you every step of the way. Our experienced team is passionate about promoting confidence and identity through education, ensuring you achieve the best possible results for your unique hair journey. Join us in embracing the beauty and versatility of natural hair in a supportive and inspiring environment.
              </p>
            </div>
          </div>

          <div className="about-us-item reverse">
            <div className="about-us-img">
              <img src={About1} alt="Our Mission" />
            </div>
            <div className="about-us-desc">
              <h2>Our Mission</h2>
              <p>
                To empower African women to embrace and celebrate their natural hair through personalized education, innovative products, and a supportive community.              </p>
            </div>
          </div>

          <div className="about-us-item">
            <div className="about-us-img">
              <img src={About1} alt="Our Story" />
            </div>
            <div className="about-us-desc">
              <h2>Our Story</h2>
              <p>
                <strong>
                  Hello, my name is Primah, and I am a certified Natural Hair Coach. <br />
                  This is my Hair Story!
                </strong>
                I founded Afroniceties out of my personal experiences and frustrations with not knowing how to care for my hair. The name was thoughtfully chosen:
                "Afro" refers to African hair, highlighting its rich heritage.
                "Niceties" captures the fine, subtle distinctions that make African hair unique. Afroniceties represents the beautiful intricacies of African hair, celebrating its uniqueness while empowering you with the knowledge needed to care for it.
                In Uganda, it's common for primary and secondary schools to require female students to cut their hair, perpetuating the belief that our hair is difficult and time-consuming to manage. This was true for me throughout my school years. For over 13 years, I forgot about my hair struggles until I finished secondary school. The reality is that Ugandan women typically confront the challenge of managing their hair around the age of 19, just as they’re beginning to develop their sense of identity. Hair, after secondary school, becomes a marker of beauty and womanhood in our culture. Yet, we never had a trusted source of information to guide us in managing our hair.
                This is why Afroniceties was created. Wherever you are on your hair journey, this is your trusted source of natural hair knowledge.
              </p>
            </div>
          </div>
        </section>
      </div>
      <TestimonialSection />
      <Footer />
    </div>
  );
}
