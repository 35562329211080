import React from "react";
import { FaArchive, FaCartPlus, FaUsers } from "react-icons/fa";
import "./CSS/Analytics.css";
import BookingCalendar from "./BookingCalendar";
import VisitorsGraph from "./VisitorsGraph";
import BookingList from "./BookingList";
import PostsData from "./Data/PostsData";
import Productdata from "./Data/ProductData";


export default function Analytics() {
  return (
    <div>
      <div className="analytics-container">
        <div className="analytics-items">
          <div className="analystics-item">
            <div className="analytics-item-lef">
              <h3>120</h3>
              <p>Total Products</p>
            </div>
            <div className="analytics-item-roght">
              <p>
                <FaArchive />
              </p>
            </div>
          </div>
          <div className="analystics-item">
            <div className="analytics-item-lef">
              <h3>20</h3>
              <p>Total Orders</p>
            </div>
            <div className="analytics-item-roght">
              <p>
                <FaCartPlus />
              </p>
            </div>
          </div>
          <div className="analystics-item">
            <div className="analytics-item-lef">
              <h3>12</h3>
              <p>Total Clients</p>
            </div>
            <div className="analytics-item-roght">
              <p>
                <FaUsers />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="graphs-se">
        <div className="visitors-gr">
            <VisitorsGraph />
        </div>
        <div className="booking-cal">
            <h1>Booking Calendar</h1>
            <BookingCalendar />
        </div>
      </div>

      <div className="booking-lists">
        <BookingList />
        <PostsData />
        <Productdata />
      </div>
    </div>
  );
}
