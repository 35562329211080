import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../Firebase/firebase'; 
import { useNavigate } from 'react-router-dom';
import '../Dashboardposts';

export default function PostsData() {
  const Navigate = useNavigate();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const querySnapshot = await getDocs(collection(db, "blogs"));
      const postsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPosts(postsList);
    };

    fetchPosts();
  }, []);

  const handleEdit = (id) => {
    console.log('Edit post with ID:', id);
    Navigate(`/Blogposts/edit/${id}`);
  };

//   const truncateHtmlContent = (htmlContent, maxWords) => {
//     const div = document.createElement('div');
//     div.innerHTML = htmlContent;
//     const textContent = div.textContent || div.innerText || '';
//     const words = textContent.split(' ');
//     if (words.length <= maxWords) return htmlContent;
//     const truncatedText = words.slice(0, maxWords).join(' ') + '...';
//     div.innerHTML = truncatedText;
//     return div.innerHTML;
//   };

  // Limit to first 10 posts
  const displayedPosts = posts.slice(0, 10);

  return (
    <>
      <div className="dashboard-posts-container">
        <h2>Blog Posts</h2>
        <ul>
          {displayedPosts.map(post => (
            <li key={post.id}>
              <p>{post.title}</p>
              {/* <div dangerouslySetInnerHTML={{ __html: truncateHtmlContent(post.content, 50) }} /> */}
              <button onClick={() => handleEdit(post.id)} style={{width:"100%", padding: "5px 10px"}}>Edit</button>
            </li>
          ))}
        </ul>
        <button style={{width:"100%", borderRadius:25, padding:"5px 10px"}}><a href="/Adminblogs" style={{textDecoration:"none", color:"#fff"}}>See all blogs</a></button>
      </div>
    </>
  );
}
