import React from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import "./ChildHeader.css";

export default function ChildHeader({ children }) {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleBack = () => {
        navigate(-1); // Navigate back in history
    };

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="child-header-header">
                <div className="top-items">
                    <div className="top-item">
                        <div className="top-header-back" onClick={handleBack}>
                            <FaChevronLeft />
                        </div>
                        <h3>{children}</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}
