import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';


export default function SingleHeader({ title }) {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1); // This will navigate to the previous page
    }

    return (
        <div style={{backgroundColor:"#8d57a2"}}>
            <div className="single-header" style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between",
                padding:5,
                gap:25
            }}>
                <div className="single-header-back-btn" style={{
                    display:"flex",
                    alignItems:"center",
                    marginLeft: 15,
                    cursor:"pointer"
                }} onClick={handleBack}>
                    <p style={{color:"#fff", fontSize:22, fontWeight:500}}>
                        <FaArrowLeft />
                    </p>
                </div>
                <div className="single-header-header" style={{margin:"auto"}}>
                    <h2 style={{ color: "#fff", fontSize: 18, padding: 10 }}>{title}</h2>
                </div>
            </div>
        </div>
    );
}
