import React from 'react'
import Navbar from "../Navbar/Navbar"
import { FaChevronRight } from 'react-icons/fa'
import './Header.css'

export default function Header({ title }) {
  return (
    <header className="header">
      <Navbar />
      <div className="header-container">
        <div className="header-title">
          <h1>{title}</h1>
        </div>
        <div className="header-breadcrumbs">
          <p>
            <span className="breadcrumb-item"><a href="/">Home</a></span> 
            <span className="breadcrumb-separator"><FaChevronRight /></span>
            <span className="breadcrumb-item"><a href="/">{title}</a></span>
          </p>
        </div>
      </div>
    </header>
  )
}
