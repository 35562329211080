import React, { useEffect, useState } from 'react'
import { db } from '../../Firebase/firebase';
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { ResponsiveLine } from "@nivo/line";

export default function VisitorsGraph() {
    const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const visitsRef = collection(db, "visits");
        const q = query(visitsRef, orderBy("date"));
        const snapshot = await getDocs(q);

        const chartData = snapshot.docs
          .map((doc) => {
            const visit = doc.data();
            let date;

            if (visit.date.toDate) {
              date = visit.date.toDate();
            } else if (typeof visit.date === "string") {
              date = new Date(visit.date);
            } else if (typeof visit.date === "number") {
              date = new Date(visit.date);
            } else {
              console.error("Unsupported date format:", visit.date);
              return null;
            }

            return { date, count: visit.count };
          })
          .filter((item) => item !== null);

        const nonZeroData = chartData.filter(
          (item, index) =>
            item.count !== 0 ||
            (index !== 0 && chartData[index - 1].count !== 0)
        );

        setData(nonZeroData);
      } catch (error) {
        console.error("Error fetching visit data: ", error);
      }
    };

    fetchData();
  }, []);

  const nivoData = [
    {
      id: "Visit Count",
      data: data.map((item) => ({
        x: item.date.toLocaleDateString(),
        y: item.count,
      })),
    },
  ];
  return (
    <div className="graph-container">
          <h2>Visit Analytics</h2>
          <div
            style={{
              height: 400,
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            }}
          >
            <ResponsiveLine
              data={nivoData}
              margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
              }}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Date",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendOffset: -40,
                legendPosition: "middle",
              }}
              colors={{ scheme: "category10" }}
              pointSize={10}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                },
              ]}
            />
          </div>
        </div>
  )
}
