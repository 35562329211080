import React, { useState, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { db } from '../../utils/Firebase/firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import RegistrationForm from './RegistrationForm';
import './Booking.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Booking() {
  const localizer = momentLocalizer(moment);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState('10:30');
  const [events, setEvents] = useState([]);
  const [notification, setNotification] = useState('');
  const [user, setUser] = useState(null);
  const [showRegistration, setShowRegistration] = useState(true);

  const handleDateChange = useCallback(date => {
    setDate(date);
  }, []);

  const handleTimeChange = useCallback(time => {
    setTime(time);
  }, []);

  const handleUserRegistration = (userInfo) => {
    setUser(userInfo);
    setShowRegistration(false);
  };

  const handleAddEvent = async () => {
    if (!user) {
      setNotification('Please register before booking.');
      return;
    }

    const [hours, minutes] = time.split(':').map(Number);
    const eventStart = new Date(date.setHours(hours, minutes));
    const eventEnd = new Date(eventStart);
    eventEnd.setHours(eventEnd.getHours() + 1);

    try {
      // Check if the time slot is already booked
      const bookingsQuery = query(
        collection(db, 'bookings'),
        where('start', '<=', eventEnd),
        where('end', '>=', eventStart)
      );

      const querySnapshot = await getDocs(bookingsQuery);

      if (!querySnapshot.empty) {
        // Notify user if booking already exists
        setNotification('This time slot is already booked. Please choose a different time.');
      } else {
        // Save event to Firestore
        await addDoc(collection(db, 'bookings'), {
          start: eventStart,
          end: eventEnd,
          user: user // Save user info
        });

        // Update local state
        setEvents([...events, { start: eventStart, end: eventEnd, title: `${user.name} - ${user.contact}` }]);
        setNotification(''); // Clear any existing notifications
        setDate(new Date()); // Reset date
        setTime('10:30');
      }
    } catch (error) {
      console.error('Error checking or adding event to Firestore: ', error);
      setNotification('An error occurred while saving your booking. Please try again.');
    }
  };

  return (
    <>
      <Header title="Appointments" />
      <div className="booking-container">
        {showRegistration ? (
          <RegistrationForm onRegister={handleUserRegistration} />
        ) : (
          <div className="booking-form">
            <h2>Select Date and Time</h2>
            <div className="picker-container">
              <DatePicker
                selected={date}
                onChange={handleDateChange}
                className="date-picker"
                placeholderText="Select date"
              />
              <TimePicker
                onChange={handleTimeChange}
                value={time}
                className="time-picker"
                clearIcon={null}
                clockIcon={null}
              />
            </div>
            <button onClick={handleAddEvent} className="add-button">Add Booking</button>
            {notification && <div className="notification">{notification}</div>}
          </div>
        )}
        <div className="calendar-container">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '400px', margin: '20px 0' }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
