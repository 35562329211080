import React from 'react';
import { BooksData } from '../Store/Store';
import BookComponent from '../BookComponent/BookComponent';
import './BookStore.css';
import Navbar from '../../components/Navbar/Navbar';

export default function BookStore() {
  const desiredCategory = 'book';
  const filteredData = BooksData.filter(book => book.category === desiredCategory);

  return (
    <>
      <Navbar />
      <div className="bookstore">
        <h1 className="store-title">Book Store</h1>
        <div className="book-list">
          {filteredData.length > 0 ? (
            filteredData.map(book => (
              <BookComponent key={book.id} book={book} />
            ))
          ) : (
            <p>No books available in this category.</p>
          )}
        </div>
      </div>
    </>
  );
}
