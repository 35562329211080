import React from 'react';
import './BookDetails.css';
import bookImage from '../../../utils/img/books/milady.jpg';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

const BookDetails = () => {
  const book = {
    title: "Art of Happiness | Lama Dalai",
    price: "UGX 80000",
    format: "Paperback",
    description: "In this unique and important book, one of the world's great spiritual leaders offers his practical wisdom and advice on how we can overcome everyday human problems and achieve lasting happiness.",
    similarProducts: [
      { title: "Big Magic", author: "Elizabeth Gilbert", price: "UGX 48000", image: bookImage },
      { title: "The Dyslexic Advantage", author: "Brock L. Eide", price: "UGX 116000", image: bookImage },
      { title: "Rewiring Education", author: "John D. Couch", price: "UGX 120000", image: bookImage },
      { title: "Pocket Book of Love", author: "Trigger Publishing", price: "UGX 40000", image: bookImage },
      { title: "Elon Musk", author: "Walter Isaacson", price: "UGX 105000", image: bookImage },
    ],
  };

  return (
    <>
    <Header title={book.title} />
    <div className="book-details">
        <div className="book-detail">

      <div className="image-section">
        <img src={bookImage} alt={book.title} className="book-image" />
      </div>
      <div className="details-section">
        <h1>{book.title}</h1>
        <p className="format"><strong>Format:</strong> {book.format}</p>
        <p className="price"><strong>Price:</strong> {book.price}</p>
        <p className="description">{book.description}</p>
        <button className="add-to-cart">Add to Cart</button>
      </div>
        </div>
      <div className="similar-products">
        <h3>Similar Products</h3>
        <div className="product-list">
          {book.similarProducts.map((product, index) => (
            <div className="product-card" key={index}>
              <img src={product.image} alt={product.title} className="product-image" />
              <p><strong>{product.title}</strong></p>
              <p>{product.author}</p>
              <p>{product.price}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default BookDetails;