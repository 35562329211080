import React from 'react';
import PropTypes from 'prop-types';

const CartItem = ({ item, onRemove, onQuantityChange }) => {
  const handleQuantityChange = (e) => {
    onQuantityChange(item.id, parseInt(e.target.value, 10));
  };

  return (
    <div className="cart-item">
      <img src={item.imgSrc} alt={item.name} />
      <div className="cart-item-details">
        <h2>{item.name}</h2>
        <p>${item.price.toFixed(2)}</p>
        <p>Quantity: 
          <input
            type="number"
            value={item.quantity}
            onChange={handleQuantityChange}
            min="1"
          />
        </p>
        <button onClick={() => onRemove(item.id)}>Remove</button>
      </div>
    </div>
  );
};

CartItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    imgSrc: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
};

export default CartItem;
