import React from 'react'
import prof from "../../img/logo.png"
import "./CSS/DashNav.css"
import { FaAngleDown } from 'react-icons/fa'

export default function DashNav() {
  return (
    <div className='dash-nav-sect'>
        <div className="dash-nav-container">
            <div className="dash-nav-items">
                <div className="dash-nav-title">
                    <h2>Dashboard</h2>
                </div>
                <div className="logged-user">
                    <h4>Prima Elizabeth</h4>
                    <img src={prof} alt="Profile" />
                    <p>
                    <FaAngleDown />
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}
