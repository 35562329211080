import React from "react";
import "./Singleproduct.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import mainImage from "../../../utils/img/Products/Hair-and-Nail-Sup.jpg";
import scalpScrub from "../../../utils/img/Products/Hairscience.webp"
import { FaStar } from "react-icons/fa";
import ProductComponents from "../../../components/ProductComponents/ProductComponents";

const productsData = [
  {
    id: 1,
    imgSrc: scalpScrub,
    productTitle: "Cleansing Scalp Scrub",
    productPrice: "240,000",
  },
  {
    id: 2,
    imgSrc: scalpScrub,
    productTitle: "Cleansing Scalp Scrub",
    productPrice: "240,000",
  },
  {
    id: 3,
    imgSrc: scalpScrub,
    productTitle: "Cleansing Scalp Scrub",
    productPrice: "240,000",
  },
  {
    id: 4,
    imgSrc: scalpScrub,
    productTitle: "Cleansing Scalp Scrub",
    productPrice: "240,000",
  },
];
export default function Singleproduct() {
  return (
    <div>
      <Header title="Single Product" />
      <div className="single-post">
        <div className="product-header">
          <img src={mainImage} alt="Product" />
          <div className="product-header-desc">
            <h3>Rep-Hair & Nail Supplements</h3>
            <p className="single-post-stars">
              Ratings: 4 <br />
              <span><FaStar /></span>
              <span><FaStar /></span>
              <span><FaStar /></span>
              <span><FaStar /></span>
            </p>
            <p className="single-post-main-desc">
              Supplements part of the four step Rep-Hair system to promote
              healthy hair growth.
            </p>
            <p className="single-post-price">UGX: 290,000</p>
            <div className="cart-btn-header">
              <input type="number" />
              <span>ADD TO CART</span>
            </div>
          </div>
        </div>
        <div className="single-post-desc">
          <h4>Description</h4>
          <div className="description">
            <p>About the System</p>
            <p className="description-content">
              Rep-Hair® Follicle Strengthening System is a natural and
              scientifically formulated daily scalp and hair care regime,
              designed to improve the strength and condition of the scalp and
              hair, providing an optimum follicular environment for hair growth.
              Rep-Hair® combines natural and organic extracts which have been
              carefully selected for their nourishing, strengthening,
              conditioning and cleansing properties, as well as their ability to
              protect the scalp and reduce inflammation of the hair follicle.
              This highly effective and natural system harnesses the unique
              benefits of Capixyl™, an elite hair care complex that has been
              proven to reduce hair loss and stimulate natural hair growth. If
              used continuously as directed, noticeable results should be seen
              within 4 – 16 weeks. We always encourage the purchase of The
              Rep-Hair® Follicle Strengthening System as a kit for best results,
              however, the Shampoo, Conditioner, Treatment and Supplements are
              now available to purchase individually. This will make it easier
              for you to top up as required so you can keep up with your daily
              scalp and hair care regime.
            </p>
          </div>
        </div>
        <div className="single-post-rel">
          <h4 className="single-post-rel-title">Related Products</h4>
          <div className="products-container">
            {productsData.map((product) => (
              <div
                key={product.id}
                className="product-item"
                style={{ maxWidth: "45%" }}
              >
                <ProductComponents
                  id={product.id}
                  imgSrc={product.imgSrc}
                  productTitle={product.productTitle}
                  productPrice={product.productPrice}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
