import React from 'react';
import { Analytics } from "@vercel/analytics/react";
import SideNav from './SideNav';
import "./CSS/Layout.css";
import DashNav from './DashNav';

export default function Layout({children}) {
  return (
    <>
    <SideNav />
    <DashNav />
    <main className='dash-main-sect'>
        {children}
        <Analytics />
    </main>
    </>
  )
}
