import React, { useState, useEffect, useContext, useRef } from 'react';
import logo from "../../utils/img/logo-03.png";
import { FaBars, FaShoppingCart } from 'react-icons/fa';
import "./Navbar.css";
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../../utils/Context/BookContext';

export default function Navbar() {
    const cart = useContext(CartContext);
    const Navigate = useNavigate();
    const [isNavVisible, setIsNavVisible] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const navRef = useRef(null);

    const handleClick = () => {
        setIsNavVisible(!isNavVisible);
    };

    const HandleCart = () => {
        Navigate("/Bookcart");
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > window.innerHeight * 0.8);
        };

        window.addEventListener('scroll', handleScroll);
        
        const handleBodyClick = (e) => {
            if (navRef.current && !navRef.current.contains(e.target)) {
                setIsNavVisible(false);
            }
        };

        document.addEventListener('click', handleBodyClick);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('click', handleBodyClick);
        };
    }, []);

    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

    return (
        <nav className={`navbar ${isSticky ? 'sticky' : ''}`} ref={navRef}>
            <div className="nav-container">
                <a href="/" className="nav-logo">
                    <img src={logo} alt="Afroniceties nav logo" />
                </a>
                <div className={`nav-lists ${isNavVisible ? 'show' : ''}`}>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/About-us">About</a></li>
                        <li><a href="/Shop">Shop</a></li>
                        <li><a href="/Blogposts">Blog</a></li>
                        <li><a href="/Videos">Videos</a></li>
                        <li><a href="/Bookings">Book Now</a></li>
                        <li><a href="/">Contact</a></li>
                    </ul>
                </div>
                <div className="nav-btns">
                    <div className="sm-screen-btn">
                        <div className="humberger">
                            <FaBars className='humbergerBtn' onClick={handleClick} />
                            <div className="nav-bar-cart">
                                <FaShoppingCart onClick={HandleCart} />
                                <span>{productsCount}</span> {/* Display the cart count */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}
