import React, { useState } from 'react';
import { db, storage } from "../../../utils/Firebase/firebase"
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import '../../Ebook/AddBook/AddBook.css'
import SingleHeader from '../../../components/Header/SingleHeader';


export default function AddProduct() {
    const [bookTitle, setBookTitle] = useState('');
    const [bookPrice, setBookPrice] = useState('');
    const [bookDesc, setBookDesc] = useState('');
    const [bookImg, setBookImg] = useState(null);

    const handleFileChange = (e) => {
        setBookImg(e.target.files[0]);
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!bookTitle || !bookPrice || !bookDesc || !bookImg) {
          alert('Please fill in all fields and select an image.');
          return;
        }
    
        try {
          // Upload image to Firebase Storage
          const imageRef = ref(storage, `bookImages/${bookImg.name}`);
          await uploadBytes(imageRef, bookImg);
          const imageUrl = await getDownloadURL(imageRef);
    
          // Add book data to Firestore
          await addDoc(collection(db, 'products'), {
            bookTitle,
            bookPrice,
            bookDesc,
            bookImg: imageUrl,
            category: 'product',
          });
    
          alert('Book added successfully!');
          setBookTitle('');
          setBookPrice('');
          setBookDesc('');
          setBookImg(null);
        } catch (error) {
          console.error('Error adding book:', error);
          alert('Error adding book.');
        }
      };
  return (
    <>
    <SingleHeader title="Create New Product"/>
    <div className="add-book-container">
      <h2>Add a New Product</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="bookTitle">Product Name</label>
          <input
            type="text"
            id="bookTitle"
            value={bookTitle}
            onChange={(e) => setBookTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="bookPrice">Product Price</label>
          <input
            type="text"
            id="bookPrice"
            value={bookPrice}
            onChange={(e) => setBookPrice(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="bookDesc">Product Description</label>
          <textarea
            id="bookDesc"
            rows="4"
            value={bookDesc}
            onChange={(e) => setBookDesc(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="bookImg">Product Image</label>
          <input
            type="file"
            id="bookImg"
            onChange={handleFileChange}
          />
        </div>
        <button type="submit">Add Product</button>
      </form>
    </div>
    </>
  )
}
