import React, { useState, useEffect } from 'react';
import { db } from '../../Firebase/firebase';
import { collection, query, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import './CSS/BookingList.css';

const BOOKINGS_PER_PAGE = 10;

export default function BookingList() {
  const [bookings, setBookings] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = async (startAfterDoc = null) => {
    setLoading(true);

    try {
      let bookingsQuery = query(
        collection(db, 'bookings'),
        orderBy('start', 'desc'), // Order by the start time
        limit(BOOKINGS_PER_PAGE)
      );

      if (startAfterDoc) {
        bookingsQuery = query(
          collection(db, 'bookings'),
          orderBy('start', 'desc'),
          startAfter(startAfterDoc),
          limit(BOOKINGS_PER_PAGE)
        );
      }

      const querySnapshot = await getDocs(bookingsQuery);
      const fetchedBookings = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          start: data.start.toDate(),
          end: data.end.toDate(),
          user: data.user
        };
      });

      setBookings(prevBookings => [...prevBookings, ...fetchedBookings]);

      // Update pagination state
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(fetchedBookings.length === BOOKINGS_PER_PAGE);
    } catch (error) {
      console.error('Error fetching bookings: ', error);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (hasMore) {
      fetchBookings(lastVisible);
    }
  };

  return (
    <div className="booking-list-container">
      <h2>Bookings</h2>
      <ul className="booking-list">
        {bookings.map(booking => (
          <li key={booking.id} className="booking-item">
            <div><strong>Name:</strong> {booking.user.name}</div>
            <div><strong>Email:</strong> {booking.user.email}</div>
            <div><strong>Contact:</strong> {booking.user.contact}</div>
            <div><strong>Start Date:</strong> {booking.start.toLocaleDateString()}</div>
            <div><strong>Start Time:</strong> {booking.start.toLocaleTimeString()}</div>
            <div><strong>End Date:</strong> {booking.end.toLocaleDateString()}</div>
            <div><strong>End Time:</strong> {booking.end.toLocaleTimeString()}</div>
          </li>
        ))}
      </ul>
      {hasMore && (
        <button onClick={loadMore} disabled={loading} className="more-button">
          {loading ? 'Loading...' : 'More'}
        </button>
      )}
    </div>
  );
}
