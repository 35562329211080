import React from 'react';
import './About.css';
import Aboutimage from '../../../utils/img/Hero/About-us.png';

export default function About() {
  return (
    <section className="about" aria-label="about">
      <div className="about__container">
        <div className="about__image">
          <img src={Aboutimage} alt="About us" />
        </div>
        <div className="about__desc">
          <h3 className="about__title">
            
           Every woman deserves to feel <span className="about__highlight">confident</span>about her hair every day.<br />
            <span className="about__subtitle">With Your Hair Coach Primah Elizabeth</span>
          </h3>
          <p className="about__text">
            With our expert team, we believe  you are your greatest hair asset. Unless you have a hairstylist on your handbag, you should never relinquish your responsibility for basic hair care in favor of complete dependence on someone else!
          </p>
          <div className="about__lists">
            <ul className="about__list">
              <li>See your hair in a new light</li>
              <li>Gain the knowledge you need to care for it</li>
              <li>Sustain the healthy habits you’ll learn with our support</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
