import React, { useContext } from 'react';
import { CartContext } from '../../utils/Context/BookContext';
import { getBookData } from '../Store/Store';
import './CartProductComponent.css';

function CartProductComponent({ id, quantity }) {
    const cart = useContext(CartContext);
    const productData = getBookData(id);

    return (
        <div className="cart-product">
            <div className="product-info">
                <h3 className="product-title">{productData.bookTitle}</h3>
                <p className="product-quantity">{quantity} Total</p>
                <p className="product-price">UGX {(quantity * productData.bookPrice).toFixed(2)}</p>
            </div>
            <button className="remove-button" onClick={() => cart.deleteFromCart(id)}> &times; </button>
            <hr className="divider" />
        </div>
    );
}

export default CartProductComponent;
